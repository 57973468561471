<template>
    <div class="shadow-lg rounded-lg bg-white px-5 pt-3 pb-5">
        <el-form ref="form" :rules="rules" :model="form">
            <div class="flex items-end">
                <div class="flex-grow">
                    <div class="grid grid-cols-12 gap-4 w-full">
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="banana_id">
                                <label class="text-gray-400">
                                    用戶 ID
                                    <el-input v-model="form.banana_id" clearable class="w-full"> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <div class="flex items-end">
                                <el-form-item class="mb-0 flex-grow-0 w-[90px]" prop="countryCode">
                                    <label class="text-gray-400">
                                        手機
                                        <el-select v-model="form.countryCode" clearable class="w-full">
                                            <el-option v-for="item in ['886']" :key="item" :value="item" :label="item"></el-option>
                                        </el-select>
                                    </label>
                                </el-form-item>
                                <el-form-item class="mb-0 flex-grow flex-1" prop="mobile">
                                    <el-input v-model.number="form.mobile" clearable class="w-full"> </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="government_id">
                                <label class="text-gray-400">
                                    身份證
                                    <el-input v-model="form.government_id" clearable class="w-full"> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="status">
                                <label class="text-gray-400">
                                    狀態
                                    <el-select v-model="form.status" class="w-full" clearable>
                                        <el-option v-for="(status, index) in statusOptions" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0 ml-4 min-w-[150px]">
                    <btn
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink mr-2"
                        @onClick="onSubmit('form')">
                        搜尋
                    </btn>
                    <btn
                        color="border border-black"
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink"
                        @onClick="resetData">
                        重置
                    </btn>
                </div>
            </div>
            <div class="flex items-end">
                <div class="flex-grow">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="name">
                                <label class="text-gray-400">
                                    暱稱
                                    <el-input v-model="form.name" clearable> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="real_name">
                                <label class="text-gray-400">
                                    真實姓名
                                    <el-input v-model="form.real_name" clearable> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="birthday">
                                <label class="text-gray-400"> 生日 </label>
                                <div class="flex">
                                    <el-select v-model="form.birthday_year" class="flex-1" clearable placeholder="年份">
                                        <el-option v-for="item in years" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                    <el-select v-model="form.birthday_month" class="flex-1" clearable placeholder="月份">
                                        <el-option v-for="item in 12" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                    <el-select v-model="form.birthday_day" class="flex-1" clearable placeholder="日期">
                                        <el-option v-for="item in 31" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </div>
                        <div v-if="isProvider" class="col-span-3">
                            <el-form-item class="mb-0" prop="roles">
                                <label class="text-gray-400">
                                    上架
                                    <el-select v-model="form.roles" class="w-full" clearable>
                                        <el-option v-for="(status, index) in alreadyOptions" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
// 日期格式套件
import moment from "moment";
// 判斷是否有選擇值
import { checkHaveSelectData } from "@/service/anyService";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    components: {
        Btn,
    },
    props: {
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            form: {
                countryCode: "886",
            },
            rules: {
                mobile: [
                    {
                        validator: this.checkPhoneFirstStringZero,
                        trigger: "blur",
                    },
                ],
                countryCode: [{ message: "國碼為必填", trigger: "change" }],
            },
            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: [0, 1, 2] },
                { label: "停權", value: [-1] },
                { label: "永久停權", value: [-2] },
            ],
            // 上架狀態
            alreadyOptions: [
                { label: "已上架", value: [1] },
                { label: "未上架", value: [2] },
            ],
            // 搜尋條件
            filterData: {},
            // 搜尋年份
            years: [],
        };
    },
    methods: {
        // 判斷手機號碼中 是否第一個字為 0
        checkPhoneFirstStringZero(rule, value, callback) {
            // 判斷沒有輸入手機號碼時不執行檢查
            if (this.$isEmpty(value)) {
                callback();
                return;
            }
            if (value.length > 0 && value[0] == 0) {
                this.$set(this.form, "phone", value.substring(1));
                callback();
            } else {
                callback();
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.filterSearch();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 搜尋條件
        async filterSearch() {
            // 先清空生日值
            this.form.birthday = null;
            // 判斷是否有輸入月份 跟 日期
            if (!this.$isEmpty(this.form.month) && !this.$isEmpty(this.form.day)) {
                // 組合生日
                this.form.birthday = moment(this.form.month + "-" + this.form.day).format("MM-DD");
            }
            // 判斷是否有輸入手機號碼
            if (!this.$isEmpty(this.form.mobile)) {
                // 組合手機號碼
                this.form.phone = this.form.countryCode + this.form.mobile;
            } else {
                // 須清空組合後得手機號碼 否則還是一樣會列入搜尋條件
                this.form.phone = null;
            }
            // 判斷是否有值 有值就帶入搜尋條件
            checkHaveSelectData(
                [
                    this.form.banana_id,
                    this.form.name,
                    this.form.real_name,
                    this.form.birthday_year,
                    this.form.birthday_month,
                    this.form.birthday_day,
                    this.form.phone,
                    this.form.status,
                    this.form.roles,
                    this.form.government_id,
                ],
                ["banana_id", "name", "real_name", "birthday_year", "birthday_month", "birthday_day", "phone", "status", "roles", "government_id"],
                this.filterData
            );
            this.$emit("onSearch", this.filterData);
        },
        // 清空表單資料
        resetData() {
            this.form = {
                countryCode: "886",
            };
            this.$refs.form.resetFields();
            this.$emit("onReset", {});
        },
        // 取得1911 至
        getYears() {
            const year = Number(moment().format("YYYY"));
            for (let i = 1911; i <= year - 18; i++) {
                this.years.unshift(i);
            }
        },
    },
    created() {
        this.getYears();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    @apply w-full;
}
</style>
