<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3 bg-white rounded-lg shadow-lg">
            <div class="p-5">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in menus"
                        v-show="item.show"
                        :key="index"
                        class="cursor-pointer text-center py-3"
                        :class="item.value === showComponent ? 'bg-red-500 bg-opacity-20 rounded-lg' : ''"
                        @click="changeShowComponent(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9 rounded-lg">
            <component :is="showComponent"></component>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
// 轉發服務商頁面
import NotifiyProvidersChat from "./components/NotifyProvidersChat.vue";
// 轉發會員頁面
import NotifyMembersChat from "./components/NotifyMembersChat.vue";
export default {
    components: {
        NotifiyProvidersChat,
        NotifyMembersChat,
    },
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        menus() {
            return [
                {
                    label: "轉發服務商",
                    value: "NotifiyProvidersChat",
                    show: this.checkPermission("NotifiyProvidersChat"),
                },
                {
                    label: "轉發會員",
                    value: "NotifyMembersChat",
                    show: this.checkPermission("NotifyMembersChat"),
                },
            ];
        },
    },
    data() {
        return {
            showComponent: "NotifiyProvidersChat",
            //  判斷是否有子項功能的選項
            routers: this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [],
        };
    },
    methods: {
        // 切換顯示組件
        changeShowComponent(val) {
            this.loading = true;
            this.showComponent = val;
        },
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
};
</script>
