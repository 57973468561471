/**
 * firebaseChatMessages: 創建聊天內容
 */
import { firebaseChatMessages, firebaseSetReadedTime } from "@/service/firebaseChatSet";

export default {
    data() {
        return {
            imagePreview: null,
            imageFile: null,
        };
    },
    methods: {
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 圖片變更時
         * @param { type File(檔案) } file 上傳檔案資料
         * @param { type Array(陣列) } fileList 檔案列表資料
         * @returns
         */
        handleFileChange(file) {
            if (file.size > 1 * 1024 * 1024) {
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            // 判斷是否有選擇對象
            if (this.users.length <= 0) {
                return;
            }
            // 清空上傳列表 不然會超過上傳檔案數量限制
            this.fileList = [];
            // 將上傳檔案存入此變數
            this.imageFile = file;
            // 新增一個讀取上傳檔案方法
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imagePreview = reader.result;
            };
        },
        /**
         * 群發上傳圖片事件
         * @param { type Array(陣列) } users 群發聊天對象
         */
        isUploadImage(users) {
            // 等待所有人員發送完畢 再進行 callback
            const bar = new Promise((resolve, reject) => {
                /**
                 * @param { type String(字串) } currentReceiveUserId 發送訊息對象 banana_id
                 */
                users.forEach(async (currentReceiveUserId, index, array) => {
                    let count = 0;
                    // 計算未讀訊息
                    count = await this.getReveiveUserUnReadTotal(currentReceiveUserId);
                    // 判斷取得未讀訊息數量失敗時不往下執行
                    if (count === false) {
                        // 判斷發送失敗名單 沒有在陣列中時 才做新增
                        if (!this.sendFailUsers.includes(currentReceiveUserId)) {
                            // 新增發送失敗名單
                            this.sendFailUsers.push(currentReceiveUserId);
                        }
                        reject(currentReceiveUserId);
                        return;
                    }
                    count += 1;
                    this.uploadAttachments(this.imageFile, currentReceiveUserId, count);
                    // 判斷為最後一筆訊息時 在回傳群發結束
                    if (index === array.length - 1) {
                        resolve();
                    }
                });
            });
            // 所有人員發送完畢  callback事件
            bar.then(() => {
                this.imageFile = null;
                this.imagePreview = null;
                this.$message({
                    type: "success",
                    message: "群發成功",
                });
                return true;
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: `群發失敗`,
                });
                return false;
            });
        },
        /**
         * 檔案上傳
         * @param { type File(檔案) } file 檔案資料
         */
        async uploadAttachments(file, currentReceiveUserId, count) {
            try {
                const { data } = await this.$api.ChatUploadAttachmentsApi(file);
                // 發送至firebase 資料
                const sendData = {
                    userId: this.serviceChatId,
                    type: "image",
                    content: data.url,
                    ownerId: this.user.id,
                };
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空檔案列表
                this.fileList = [];
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (聊天對象)
                this.setUnReadCountAndLastMessage({
                    unReadCount: count,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: "傳送一張照片",
                    loginUserId: currentReceiveUserId,
                    receiveUserId: this.serviceChatId,
                    needUpdateUserUnReadMessageCount: this.serviceChatId === process.env.VUE_APP_SERVICE_CHAT_ID ? false : true,
                });
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (自己)
                this.setUnReadCountAndLastMessage({
                    unReadCount: 0,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: "傳送一張照片",
                    loginUserId: this.serviceChatId,
                    receiveUserId: currentReceiveUserId,
                    needUpdateUserUnReadMessageCount: false,
                });
                // 更新聊天對象已讀訊息時間
                await firebaseSetReadedTime(currentReceiveUserId, this.serviceChatId);
                // 發送至對方聊天室
                firebaseChatMessages(sendData, currentReceiveUserId, this.serviceChatId);
                // 發送至自己聊天室
                firebaseChatMessages(sendData, this.serviceChatId, currentReceiveUserId);
                // 尋找發送失敗名單中的 index 索引
                const findFailUserIndex = this.sendFailUsers.findIndex((item) => item === currentReceiveUserId);
                if (findFailUserIndex !== -1) {
                    // 從發送失敗名單中移除
                    this.sendFailUsers.splice(findFailUserIndex, 1);
                }
            } catch (err) {
                // 判斷發送失敗名單 沒有在陣列中時 才做新增
                if (!this.sendFailUsers.includes(currentReceiveUserId)) {
                    // 新增發送失敗名單
                    this.sendFailUsers.push(currentReceiveUserId);
                }
                // 清空檔案列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "上傳檔案失敗",
                });
            }
        },
    },
};
