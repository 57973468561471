import { mapState, mapActions } from "vuex";
// 連接 firebase 資料
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseChatMessages: 創建聊天內容
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 */
import { firebaseChatMessages, firebaseSetReadedTime } from "@/service/firebaseChatSet";

export default {
    computed: {
        ...mapState("userStore", ["user"]),
    },
    data() {
        return {
            message: "",
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        };
    },
    methods: {
        ...mapActions("chatStore", ["getChatUsers", "setUnReadCountAndLastMessage"]),
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 發送訊息
         * @param { type Array(陣列) } users 要發送訊息的對象
         */
        async isSendMessage(users) {
            // 判斷沒有輸入訊息時 不能做送出
            if (this.message.length < 1) {
                return;
            }
            const data = {
                content: this.message,
                userId: this.serviceChatId,
                ownerId: this.user.id,
            };
            // 等待所有人員發送完畢 再進行 callback
            const bar = new Promise((resolve, reject) => {
                /**
                 * @param { type String(字串) } currentReceiveUserId 發送訊息對象 banana_id
                 */
                users.forEach(async (currentReceiveUserId, index, array) => {
                    let count = 0;
                    // 計算未讀訊息
                    count = await this.getReveiveUserUnReadTotal(currentReceiveUserId);
                    // 判斷取得未讀訊息數量失敗時不往下執行
                    if (count === false) {
                        // 判斷發送失敗名單 沒有在陣列中時 才做新增
                        if (!this.sendFailUsers.includes(currentReceiveUserId)) {
                            // 新增發送失敗名單
                            this.sendFailUsers.push(currentReceiveUserId);
                        }
                        reject(currentReceiveUserId);
                        return;
                    }

                    count += 1;
                    // 將訊息發送給對方 firebase 聊天室資料庫
                    this.receiveMessage(data, count, currentReceiveUserId);
                    // 發送訊息給自己 firebase 聊天室資料庫
                    this.sendMessage(data, currentReceiveUserId);
                    // 判斷為最後一筆訊息時 在回傳群發結束
                    if (index === array.length - 1) {
                        resolve();
                    }
                });
            });
            // 所有人員發送完畢  callback事件
            bar.then(() => {
                // 清空輸入匡訊息;
                this.clearnMessage();
                this.$message({
                    type: "success",
                    message: "群發成功",
                });
                return true;
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: `群發失敗`,
                });
                return false;
            });
        },
        /**
         * 取得聊天對象未讀數量
         * @param { type String(字串) } currentReceiveUserId 發送對象 id
         * @returns
         */
        async getReveiveUserUnReadTotal(currentReceiveUserId) {
            try {
                const data = await firebaseConnectRef("chat_rooms")
                    .child(currentReceiveUserId)
                    .child("users")
                    .child(this.serviceChatId)
                    .once("value");

                if (data == null) {
                    return 0;
                }

                return this.$isEmpty(data.val().unReadMessageCount) ? 0 : data.val().unReadMessageCount;
            } catch (err) {
                // console.log(err, "取得聊天對象未讀訊息數量失敗");
                return false;
            }
        },
        /**
         * 接收訊息對象
         * @param { type Object(物件) } data 聊天室內容
         * @param { type Number(數量) } unReadCount 未讀訊息數量
         * @param { type String(字串) } currentReceiveUserId 發送對象 id
         */
        async receiveMessage(data, unReadCount, currentReceiveUserId) {
            // 發送聊天室訊息
            await firebaseChatMessages(data, currentReceiveUserId, this.serviceChatId);
            // 設定 未讀訊息數量 與最後一筆 聊天室內容
            await this.setUnReadCountAndLastMessage({
                unReadCount,
                lastMsgAt: this.$moment().valueOf(),
                lastMessage: data.content,
                loginUserId: currentReceiveUserId,
                receiveUserId: this.serviceChatId,
                // 當聊天對象為客服時 不觸發 更新 users 中指定指定對象未讀訊息數量機制 (因為 cityai 不用理會 服務商中 與客服對話的未讀訊息數量)
                needUpdateUserUnReadMessageCount: this.serviceChatId === process.env.VUE_APP_SERVICE_CHAT_ID ? false : true,
            });
            // 更新聊天對象已讀訊息時間
            await firebaseSetReadedTime(currentReceiveUserId, this.serviceChatId);
            // 尋找發送失敗名單中的 index 索引
            const findFailUserIndex = this.sendFailUsers.findIndex((item) => item === currentReceiveUserId);
            if (findFailUserIndex !== -1) {
                // 從發送失敗名單中移除
                this.sendFailUsers.splice(findFailUserIndex, 1);
            }
        },
        /**
         * 發送訊息人員
         * @param { type Object(物件) } data 聊天室內容
         * @param { type String(字串) } currentReceiveUserId 發送對象 id
         */
        async sendMessage(data, currentReceiveUserId) {
            try {
                // 發送聊天室訊息
                await firebaseChatMessages(data, this.serviceChatId, currentReceiveUserId);
                // 設定 未讀訊息數量 與最後一筆 聊天室內容
                await this.setUnReadCountAndLastMessage({
                    unReadCount: 0,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: data.content,
                    loginUserId: this.serviceChatId,
                    receiveUserId: currentReceiveUserId,
                    needUpdateUserUnReadMessageCount: false,
                });
                // 判斷為系統客服聊天對象時不執行
                if (this.serviceChatId === this.adminChatId) {
                    return;
                }
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 清空輸入匡訊息內容
         */
        clearnMessage() {
            this.message = "";
        },
    },
    created() {},
};
